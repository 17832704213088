import React, { Fragment } from 'react';
import Headroom from 'react-headroom';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import { SectionLinks } from 'react-scroll-section';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RouteLink from './RouteLink';
import LinkAnimated from './LinkAnimated';

const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

const HeaderContainer = styled(Headroom)`
  .headroom--pinned {
    background: ${props => props.theme.colors.navy};
    box-shadow: 0 10px 30px -10px ${props => props.theme.colors.shadowNavy};
  }

  position: absolute;
  width: 100%;
`;

const formatLinks = allLinks =>
  Object.entries(allLinks).reduce(
    (acc, [key, value]) => {
      const isHome = key === 'home';
      return isHome
        ? {
            ...acc,
            home: value,
          }
        : {
            ...acc,
            links: [...acc.links, { name: capitalize(key), value }],
          };
    },
    { links: [], home: null },
  );

const Header = () => (
  <HeaderContainer>
    <Fade top>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <SectionLinks>
          {({ allLinks }) => {
            const { home, links } = formatLinks(allLinks);

            const homeLink = home && (
              <FontAwesomeIcon
                icon={['fab', 'korvue']}
                alt="Portfolio Logo"
                onClick={home.onClick}
                style={{
                  cursor: 'pointer',
                }}
              />
            );
            const navLinks = links.map(({ name, value }) => (
              <RouteLink
                key={name}
                onClick={value.onClick}
                selected={value.selected}
                name={name}
              />
            ));

            const resumeLink = (
              <Box ml={[2, 3]} color="background" fontSize={[2, 3]}>
                <LinkAnimated
                  selected={false}
                  onClick={() => window.open('kylekermgard-resume.pdf')}
                >
                  Resume
                </LinkAnimated>
              </Box>
            );

            return (
              <Fragment>
                <Box mx={3} fontSize={[6, 6, 7]} color="primary">
                  {homeLink}
                </Box>
                <Flex mr={[0, 3, 5]}>
                  {navLinks}
                  {resumeLink}
                </Flex>
              </Fragment>
            );
          }}
        </SectionLinks>
      </Flex>
    </Fade>
  </HeaderContainer>
);

export default Header;
